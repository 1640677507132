/* personalizar a barra em geral, aqui estou definindo 10px de largura para a barra vertical
e 10px de altura para a barra horizontal */
::-webkit-scrollbar {
    width: 7px;
    height: 6px;
    border-radius: 50px;
}

/* aqui é para personalizar o fundo da barra, neste caso estou colocando um fundo cinza escuro*/

/* aqui é a alça da barra, que demonstra a altura que você está na página
    estou colocando uma cor azul clara nela*/
::-webkit-scrollbar-thumb {
    background: rgb(192, 192, 192);
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(158, 157, 157);
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg{
    padding-bottom:15px !important;
    padding-top:15px !important;

}
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret{
    top: 15px !important;
}