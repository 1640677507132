.container {
  
  width: 100%;
  display: flex;
  justify-content: center;
}

.formDiv {
  width: 29rem;

  border-radius: 10px;

}

.alert {
  text-align: center;
  margin-top: 20px;
}

.creditCard {
  margin-bottom: 5%;
}

.form-check-label {
  display: flex;
  margin-bottom: 14px;
  cursor: pointer;
}

.form-check-input {
  width: 1.3em;
  background-color: #fff;
  height: 1.3em;
}